import { css } from "@emotion/react";

export const paidBookingsCss = css`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 5px 0 0 2vw; /* Reduced top margin */
  width: 100%;
  overflow-x: hidden;

  /* Table Styling */
  & .ant-table-wrapper {
    width: 97%;

    & .ant-spin-nested-loading {
      & .ant-spin-container {
        position: relative;

        /* Table Header */
        & .ant-table {
          & .ant-table-container {
            & .ant-table-header {
              font-size: 1em; /* Further reduced font size for header */
              font-weight: bold;

              /* Header Cell Styling */
              & .ant-table-cell {
                background-color: #D79922; /* Brand color for header background */
                color: white; /* White text for contrast */
                border: none;
                padding: 8px; /* Reduced padding for header cells */
                text-align: center; /* Center text for header */
                border-bottom: 1px solid #E5E5E5; /* Thinner bottom border for clarity */
              }
            }

            /* Table Body Styling */
            & .ant-table-body {
              font-size: 0.85em; /* Reduced font for body */
              
              /* Alternating Row Colors for Differentiation */
              & .ant-table-tbody {
                & .ant-table-row {
                  transition: background-color 0.3s ease;

                  /* Alternating background colors */
                  &:nth-of-type(odd) {
                    background-color: #f8f8f8; /* Light background */
                  }

                  &:nth-of-type(even) {
                    background-color: #ffffff; /* White background */
                  }

                  &:hover {
                    background-color: #e8e8e8; /* Highlight row on hover */
                  }

                  /* Differentiating Data Cells */
                  & .ant-table-cell {
                    padding: 8px; /* Further reduced padding for data cells */
                    color: #333;
                    border: none;
                    text-align: center; /* Center text for data cells */
                  }

                  /* Left Fixed Column Cells */
                  & .ant-table-cell-fix-left {
                    background-color: #4E4E50; /* Brand color */
                    padding: 8px;
                    color: white;
                  }

                  /* Right Fixed Column Cells */
                  & .ant-table-cell-fix-right-first {
                    background-color: #6F2232;  /* Brand hover color */
                    padding: 8px;
                    color: white;
                  }
                }
              }
            }
          }
        }

        /* Pagination Styling */
        & .ant-pagination {
          position: absolute;
          left: 25vw;

          & .ant-pagination-options {
            display: none;
          }

          & .ant-pagination-item {
            border-radius: 15px; /* Reduced border-radius */
            background: whitesmoke;
            margin: 0 2px; /* Further reduced margin between pagination items */
            padding: 2px 5px; /* Further reduced padding for pagination items */
          }

          & .ant-pagination-item-active {
            border-radius: 15px;
            background: #4E4E50;
            color: white;
          }

          & .ant-pagination-prev .ant-pagination-item-link,
          & .ant-pagination-next .ant-pagination-item-link {
            border-radius: 15px;
            background-color: #D79922;
          }
        }
      }
    }
  }

  /* Mojave Table Specific Styling */
  & .mojave-table {
    & .ant-table-wrapper {
      & .ant-spin-nested-loading {
        & .ant-spin-container {
          & .ant-table {
            & .ant-table-container {
              & .ant-table-header {
                & .ant-table-cell {
                  color: #F13C20; /* Header text color */
                  background-color: #D79922; /* Header background */
                  text-align: center;
                }

                & .ant-table-cell-fix-left, .ant-table-cell-fix-right-first {
                  color: #C5CBE3; /* Fixed columns color */
                  background-color: #D79922;
                }
              }

              & .ant-table-body {
                & .ant-table-tbody {
                  & .ant-table-row {
                    & .ant-table-cell {
                      background-color: #EFE2BA; /* Cell background */
                      color: black;
                      padding: 8px; /* Further reduced padding */
                      border: none;
                      text-align: center; /* Center text for body cells */
                    }

                    & .ant-table-cell-fix-left {
                      background-color: #4E4E50;
                      padding: 8px;
                      color: white;
                    }

                    & .ant-table-cell-fix-right-first {
                      background-color: #6F2232;
                      padding: 8px;
                      color: white;
                    }
                  }
                }
              }
            }
          }

          & .ant-pagination {
            & .ant-pagination-options {
              display: none;
            }

            & .ant-pagination-item {
              background-color: #C5CBE3; /* Pagination item color */
              border: none;
            }

            & .ant-pagination-prev .ant-pagination-item-link,
            & .ant-pagination-next .ant-pagination-item-link {
              background-color: #F13C20;
              border: none;
              color: white;
            }
          }
        }
      }
    }
  }

  /* Responsive Styling */
  @media (max-width: 700px) {
    & .ant-table-wrapper {
      width: 95%;

      & .ant-spin-nested-loading {
        & .ant-spin-container {
          position: relative;

          & .ant-table {
            & .ant-table-container {
              & .ant-table-header {
                font-size: 0.9em; /* Further reduced font for small screens */

                & .ant-table-cell {
                  border: none;
                }
              }

              & .ant-table-body {
                font-size: 0.85em;

                & .ant-table-tbody {
                  tr {
                    height: 60%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const actionBtnsCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  & .action-button {
    border: none;
    border-radius: 4px; /* Further reduced border radius */
    width: 65%; /* Further reduced button width */
    cursor: pointer;
    font-weight: 500;
    margin: 4px 0; /* Further reduced margin between buttons */
    font-size: 11px; /* Further reduced font size */
    padding: 6px 10px; /* Further reduced padding for buttons */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Gradient background for a modern look */
    background: linear-gradient(135deg, #42A29E, #3AA17E);
    color: white;

    /* Add shadow for a lifted effect */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

    /* Smooth transition for hover effects */
    transition: all 0.2s ease-in-out;

    /* Icon styling inside the button */
    & i {
      margin-right: 4px; /* Further reduced space between icon and text */
      font-size: 1em;
    }

    &:hover {
      background: linear-gradient(135deg, #3AA17E, #42A29E); /* Hover effect with reversed gradient */
      transform: translateY(-1px); /* Slight lift on hover */
    }

    &:active {
      transform: translateY(0px); /* No lift on active click */
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1); /* Further reduced shadow on click */
    }
  }
`

export const actionLightBtnsCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  & .action-button {
    border: none;
    border-radius: 4px; /* Further reduced border radius */
    width: 65%; /* Further reduced button width */
    cursor: pointer;
    font-weight: 500;
    margin: 4px 0; /* Further reduced margin between buttons */
    font-size: 11px; /* Further reduced font size */
    padding: 6px 10px; /* Further reduced padding for buttons */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Gradient background for a modern look */
    background: linear-gradient(135deg, #6F2232, #A62232);
    color: white;

    /* Add shadow for a lifted effect */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

    /* Smooth transition for hover effects */
    transition: all 0.2s ease-in-out;

    /* Icon styling inside the button */
    & i {
      margin-right: 4px; /* Further reduced space between icon and text */
      font-size: 1em;
    }

    &:hover {
      background: linear-gradient(135deg, #A62232, #6F2232); /* Hover effect with reversed gradient */
      transform: translateY(-1px); /* Slight lift on hover */
    }

    &:active {
      transform: translateY(0px); /* No lift on active click */
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1); /* Further reduced shadow on click */
    }
  }
`
