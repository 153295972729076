import { css } from "@emotion/react";

export const analyticsCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2% 0 0 2vw;
  height: 85%;
  overflow-y: hidden;
  overflow-x: hidden;

  /* Loader styling */
  & .analytic-loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50vh; /* Centering loader */
  }

  .analytics-grid {
    display: flex;
    justify-content: space-between;
    gap: 30px; /* Increased space between the charts */
    padding: 10px;
    max-width: 100%;
  }

  .bar,
  .doughnut {
    width: 48%;
    background: transparent; /* Make background transparent */
    box-shadow: none; /* Remove box shadow */
    border-radius: 12px;
  }

  /* Grid container for charts */
  & .analytics-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 98%;
    gap: 20px; /* Space between the charts for better layout */

    /* Doughnut chart styling */
    & .doughnut {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;

      /* Light theme */
      &.light {
        background-color: transparent; /* Transparent background */

        /* Chart specific styles for light mode */
        & .apexcharts-legend-text {
          color: #fff;
          font-size: 18px; /* Increased font size for better visibility */
        }

        & .apexcharts-xaxis-label,
        & .apexcharts-yaxis-label {
          color: #fff;
          font-size: 16px; /* Increased font size for axis labels */
        }
      }

      /* Dark theme */
      &.dark {
        background-color: transparent; /* Transparent background */

        /* Chart specific styles for dark mode */
        & .apexcharts-legend-text {
          color: #fff;
          font-size: 18px; /* Increased font size for better visibility */
        }

        & .apexcharts-xaxis-label,
        & .apexcharts-yaxis-label {
          color: #fff;
          font-size: 16px; /* Increased font size for axis labels */
        }
      }

      /* Chart specific styles */
      & .apexcharts-canvas {
        width: 100%;
        height: auto;

        /* Legend styling */
        & .apexcharts-legend {
          & .apexcharts-legend-series {
            & .apexcharts-legend-text {
              font-size: 18px; /* Larger font size for legends */
              font-weight: 500;
              text-transform: capitalize;
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    /* Bar chart styling */
    & .bar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;

      /* Light theme */
      &.light {
        background-color: transparent; /* Transparent background */

        /* Chart specific styles for light mode */
        & .apexcharts-legend-text {
          color: #fff;
          font-size: 18px; /* Increased font size for better visibility */
        }

        & .apexcharts-xaxis-label,
        & .apexcharts-yaxis-label {
          color: #fff;
          font-size: 16px; /* Increased font size for axis labels */
        }
      }

      /* Dark theme */
      &.dark {
        background-color: transparent; /* Transparent background */

        /* Chart specific styles for dark mode */
        & .apexcharts-legend-text {
          color: #fff;
          font-size: 18px; /* Increased font size for better visibility */
        }

        & .apexcharts-xaxis-label,
        & .apexcharts-yaxis-label {
          color: #fff;
          font-size: 16px; /* Increased font size for axis labels */
        }
      }

      /* Chart legend and label improvements */
      & .apexcharts-canvas {
        & .apexcharts-legend {
          & .apexcharts-legend-text {
            font-size: 18px; /* Larger font size for legends */
            font-weight: 500;
          }
        }

        /* Axis labels */
        & .apexcharts-xaxis-label,
        & .apexcharts-yaxis-label {
          font-size: 16px; /* Increased font size for axis labels */
          font-weight: 400;
          color: #666; /* Slight contrast for readability */
        }
      }
    }
  }

  /* Responsive Design for smaller screens */
  @media (max-width: 768px) {
    & .analytics-grid {
      flex-direction: column;
      align-items: center;

      /* Make charts stack vertically */
     & .doughnut,
      & .bar {
        width: 90%; /* Full width on smaller screens */
        margin-bottom: 20px;
      }
    }
  }
`;
