import React from 'react';
import { layout } from '../add-users/add-users.component';
import { themeColorPalettes } from '../../utils/utilities';

import './update-profile.styles.less';
import { Form, Input, Button } from 'antd';
import { IProfile } from '../../utils/interfaces';

interface IUpdateProfileProps {
  theme: string;
  profile?: IProfile;
}

const UpdateProfile: React.FC<IUpdateProfileProps> = ({ theme, profile }) => {

  const onFinish = (values: any) => {
    console.log(values);
    // Here you could make an API call to update the profile with `values`
  };

  return (
    <div
      className="update-profile"
      style={{ 
        padding: 24,
        minHeight: 'auto',
        backgroundColor: themeColorPalettes[theme ? theme : 'default'].textColor,
      }}
    >
      <Form 
        {...layout} 
        name="nest-messages" 
        onFinish={onFinish} 
        className={`${theme === 'default' ? 'label-styling' : (theme === 'Mojave' ? 'mojave-styling' : '')}`}
      >
        <Form.Item 
          name='password'
          label="Password" 
          rules={[
            { 
              required: true, 
              message: 'Please input your password!', 
            },
            {
              min: 6,
              message: 'Password must be at least 6 characters long.',
            }
          ]}
          colon={false}
        >
          <Input.Password placeholder='Enter new password'/>
        </Form.Item>

        <Form.Item 
          name='phone_number' 
          label="Phone Number" 
          rules={[
            { 
              required: true, 
              message: 'Please input your phone number!', 
            },
            {
              pattern: /^[0-9]{10}$/,
              message: 'Phone number must be 10 digits.',
            }
          ]}
          colon={false}
        >
          <Input placeholder={profile?.phone_number || 'Enter phone number'}/>
        </Form.Item>

        <Form.Item 
          name='department' 
          label='Department' 
          rules={[
            { 
              required: true, 
              message: 'Please input your department!', 
            }
          ]}
          colon={false}
        >
          <Input placeholder={profile?.department || 'Enter department'} />
        </Form.Item>

        <Form.Item 
          name='role' 
          label="Role" 
          rules={[
            { 
              required: true, 
              message: 'Please input your role!', 
            }
          ]}
          colon={false}
        >
          <Input placeholder={profile?.assigned_role || 'Enter role'} />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button 
            htmlType="submit" 
            style={{ 
              backgroundColor: themeColorPalettes[theme].extraColor,
              color: '#fff' // Optional: Ensures text is readable
            }}>
            Update Info
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default UpdateProfile;
