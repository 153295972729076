import { css } from "@emotion/react";

export let headerCss = css`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & .logo-img {
    width: 10rem;
    padding: 0 0 0 1rem;
    height: auto;
  }

  & .logo-img-sm {
    width: 6rem;
    padding: 0 0 0 1rem;
    height: auto;
  }

  & .header {
    width: 30%;
    display: flex;
    flex-direction: row-reverse;

    & .ant-typography {
      font-size: 1.4em;
      text-transform: uppercase;
    }
  }

  & .logo {
    max-width: 15%;
    padding: 0 0 0 20px;

    & .ant-typography {
      font-size: 1.3em;
    }
  }

`
export let sierraCss = css`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & .logo-img {
    width: 10rem;
    padding: 0 0 0 1rem;
    height: auto;
  }
  & .logo-img-sm {
    width: 6rem;
    padding: 0 0 0 1rem;
    height: auto;
  }
  & .square-logo-img {
    width: auto;
    padding: 0 0 0 1rem;
    height: 3.5rem;
  }

  & .square-logo-img-sm {
    width: auto;
    padding: 0 0 0 1rem;
    height: 2rem;
  }
  & .drawer-button {
    margin-right: 3vw;
    border-radius: 20px;
    font-size: 2em;
    text-align: center;
    cursor: pointer;
  }
  & .header {
    width: 30%;
    display: flex;
    flex-direction: row-reverse;
    & .ant-typography {
      font-size: 1.4em;
      text-transform: uppercase;
    }
  }
  & .logo {
    max-width: 15%;
    padding: 0 0 0 20px;
    & .ant-typography {
      font-size: 1.3em;
    }
  }
  & .sierra-theme {
    & .ant-drawer {
      & .ant-drawer-content-wrapper {
        & .ant-drawer-content {
          background-color: #1F2833;
          color: white;
          & .ant-drawer-header-no-title {
            background-color: #0b0c10;
            & .ant-drawer-close {
              & .anticon {
                color: #66fcf1;
              }
            }
          }
          & .ant-drawer-header {
            background-color: #0b0c10;
            color: #66fcf1;
            & .ant-drawer-header-title {
              justify-content: flex-end;
              & .ant-drawer-close {
                & .anticon {
                  color: #66fcf1;
                }
              }
            }
          }
          & .ant-drawer-body {
            & .ant-menu { 
              background-color: #1F2833;
              color: white;
              & .ant-menu-item {
                background-color: #1F2833;
                color: white;
              }
              & .ant-menu-item-selected {
                background-color: #66fcf1;
                color: #0b0c10;
              }
            }
          }
        }
      }
    }
  }
`