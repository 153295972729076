/** @jsx jsx */
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";

import { paidBookingsColumnData } from "../../utils/initialData";
import { Table, Descriptions, Typography, Space, Button, Modal } from "antd";
import Filters from "../filters/filters.component";
import { ToastContainer, toast } from "react-toastify";
import {
  useFetchQr,
  useGetPaidBookings,
  usePaymentTransactionAPI,
} from "../../utils/customHooks";
import { useNavigate } from "react-router-dom";
import { themeColorPalettes } from "../../utils/utilities";
import { AiOutlineClose } from "react-icons/ai";
import Axios from "axios";
import AppContext from "../../utils/context";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { paidBookingsCss, actionBtnsCss, actionLightBtnsCss } from "./paid-bookings.styles";
import { CSVLink } from "react-csv";
import { jsx } from "@emotion/react";

const { Title } = Typography;

const PaidBookings: React.FC<any> = ({ 
  theme, 
  userDetails
}) => {
  const context = useContext(AppContext);
  const contextRef = useRef(context);
  const [columns, setColumns] = useState<any>([]);
  const [paidTickets, setPaidTickets] = useState([]);
  const [csvData, setCsvData] = useState<any>([]);
  const [nextPageNumber, setNextPageNumber] = useState(1);
  const [paginationType, setPaginationType] = useState("normal");
  const [searchQuery, setSearchQuery] = useState("");
  const [paginationMetaData, setPaginationMetaData] = useState(30);
  const [loading, setLoading] = useState(true);
  const [showManifest, setShowManifest] = useState(false);
  const [manifestData, setManifestData] = useState<any>({});
  const [transactBookingId, setTransactBookingId] = useState("");
  const [paymentData, setPaymentData] = useState<any>();
  const navigate = useNavigate();

  const [nextRolePage, setNextRolePage] = useState(1);
  const [qrLoading, setQrLoading] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [qrUrl, setQrUrl] = useState('')

  const { state: qrCodeState } = useFetchQr(qrUrl)

  const { assigned_role: assignedRole } = context.profileInfo;
  const { innerWidth, innerHeight } = context.windowSize;

  const showQrModal = () => {
    setQrOpen(true);
    setQrUrl('https://dev.usermanagementapi.buupass.com/user-management/users/authenticator/qr-code')
  };

  const handleQrOk = () => {
  };

  const handleQrCancel = () => {
    setQrOpen(false);
  };

  const { state: paidBookingState } = useGetPaidBookings(
    `${
      process.env.REACT_APP_MARKETPLACE_URL
    }dashboard/ticket/unsent?is_customer_care=is_customer_care&is_operator=${
      assignedRole === "easycoach_user"
        ? "easycoach"
        : assignedRole === "smartcoach_user"
        ? "smartcoach"
        :  assignedRole === "trips_customer_care"
        ? "trips"
        : "admin"
    }&paid_with_tickets=paid_with_tickets&page=`,
    nextRolePage
  );


  const { state: transactionState } =
    usePaymentTransactionAPI(transactBookingId);

  useEffect(() => {
    if (paidBookingState.status === "fetched") {
      console.log("paid bookings fetched", paidBookingState.data);
    } else if (paidBookingState.status === "fetching") {
      console.log("paid bookings fetching", paidBookingState.data);
    } else if (paidBookingState.status === "idle") {
      console.log("paid bookings idle", paidBookingState.data);
    } else {
      console.log("paid bookings error", paidBookingState.data);
      // navigate("/login");
    }
  }, [paidBookingState, navigate]);

  useEffect(() => {
    if (qrCodeState.status === "fetched") {
      console.log("qr code fetched", qrCodeState);
    } else {
      // console.log("paid bookings error", qrCodeState);
    }
  }, [qrCodeState, navigate]);

  // useEffect(() => {
  //   if (profileState.status === "fetched") {
  //     console.log("profile fetched", profileState.data);
  //   } else if (profileState.status === "fetching") {
  //     console.log("profile fetching", profileState.data);
  //   } else if (profileState.status === "idle") {
  //     console.log("profile idle", profileState.data);
  //   } else {
  //     console.log("profile error", profileState.data);
  //     navigate('/login')
  //   }
  // }, [profileState, navigate]);

  // side effect for all paid bookings retrieval
  // marketplace returns rows of paid bookings

  // const handlePaidTickets = useCallback(() => {
  //   // 0727580629
  //   if (paidBookingState.status === "fetched") {
  //     let resBody = paidBookingState.data;
  //     let parsedArray = resBody.rows.map((ticketHolder: any, idx: Number) => {
  //       let newTicketInfo = {
  //         ...ticketHolder,
  //         key: idx,
  //         sms: ticketHolder ? ticketHolder.sms.message : "",
  //         name: ticketHolder.name
  //           ? ticketHolder.name
  //           : ticketHolder.passengers[0].full_name,
  //       };
  //       return newTicketInfo;
  //     });
  //     console.log("paidTickets", parsedArray);
  //     setPaidTickets(parsedArray);
  //     setPaginationMetaData(resBody.paginationMeta.totalPages);
  //     setLoading(false);
  //   } else if (paidBookingState.status === "fetching") {
  //   } else if (paidBookingState.status === "idle") {
  //     setLoading(false);
  //   } else {
  //     setLoading(false);
  //     toast.error(paidBookingState.error, {
  //       position: "top-right",
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       autoClose: 5000,
  //     });
  //   }
  // }, [paidBookingState]);

  useEffect(() => {
    if (paidTickets.length > 0) return;
    // handlePaidTickets()
    if (paidBookingState.status === "fetched") {
      let resBody = paidBookingState.data.data;
      contextRef.current.addBookingsCount(paidBookingState.data.bookings_count);
      let parsedArray = resBody.rows.map((ticketHolder: any, idx: Number) => {
        let newTicketInfo = {
          ...ticketHolder,
          key: idx,
          sms: ticketHolder ? ticketHolder.sms.message : "",
          name: ticketHolder.name
            ? ticketHolder.name
            : ticketHolder.passengers[0].full_name,
          departure_date: ticketHolder.departure_date
            ? ticketHolder.departure_date.split("-").reverse().join("/")
            : "",
          booking_date: ticketHolder.created_at
            ? ticketHolder.created_at
                .split("T")[0]
                .split("-")
                .reverse()
                .join("/")
            : "",
        };
        return newTicketInfo;
      });
      
      if (context.profileInfo.assigned_role === "trips") {
        parsedArray = parsedArray.map(((ticket: any, idx: number) => {
          return {
            key: idx,
            booking_date: ticket.booking_date,
            name: ticket.name,
            phone: ticket.phone,
            id: ticket.id,
            trade_id: ticket.trade_id,
            source: ticket.source,
            destination: ticket.destination,
            departure_date: ticket.departure_date,
            total_amount: ticket.total_amount
          }
        }))
        console.log("paidTickets trips", parsedArray);

        setCsvData(parsedArray)
      } else {
        
      }
      setPaidTickets(parsedArray);
      setPaginationMetaData(resBody.paginationMeta.totalPages);
      setLoading(false);
    } else if (paidBookingState.status === "fetching") {
      setLoading(true);
    } else if (paidBookingState.status === "idle") {
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(paidBookingState.error, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: 5000,
      });
    }
    return () => {};
  }, [paidBookingState, paidTickets]);

  // side effect on table pagination

  useEffect(() => {
    // console.log('pagination', state)
    if (paidBookingState.status === "fetched") {
      let resBody = paidBookingState.data.data;
      contextRef.current.addBookingsCount(paidBookingState.data.bookings_count);
      let parsedArray = resBody.rows.map((ticketHolder: any, idx: Number) => {
        let newTicketInfo = {
          ...ticketHolder,
          key: idx,
          sms: ticketHolder
            ? ticketHolder.sms.message
            : ticketHolder.operator_reservation_response
            ? ticketHolder.operator_reservation_response
            : "",
          name: ticketHolder.name
            ? ticketHolder.name
            : ticketHolder.passengers[0].full_name,
          departure_date: ticketHolder.departure_date
            ? ticketHolder.departure_date.split("-").reverse().join("/")
            : "",
          booking_date: ticketHolder.created_at
            ? ticketHolder.created_at
                .split("T")[0]
                .split("-")
                .reverse()
                .join("/")
            : "",
        };
        return newTicketInfo;
      });
      setPaidTickets(parsedArray);
      setPaginationMetaData(resBody.paginationMeta.totalPages);
      setLoading(false);
    } else if (paidBookingState.status === "fetching") {
    } else if (paidBookingState.status === "idle") {
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(paidBookingState.error, {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: 5000,
      });
    }
    return () => {};
  }, [paidBookingState, nextRolePage]);

  // side effect handling 'View Transaction Details'
  useEffect(() => {
    if (!transactBookingId) return;
    if (transactionState.status === "fetched") {
      console.log("transaction fetched", transactionState.data);
      setTransactBookingId("");
      setPaymentData(transactionState.data);
      setLoading(false);
    } else if (transactionState.status === "fetching") {
      console.log("transactBookingId fetching", transactBookingId);
      console.log("transaction fetching", transactionState.data);
    } else if (transactionState.status === "idle") {
      console.log("transaction idle", transactionState.data);
    } else {
      console.log("transaction error", transactionState.error);
      toast.error("No payment transaction for this booking ID", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: 5000,
      });
      // history.push('/login')
    }
  }, [transactionState, transactBookingId]);

  // event handlers for action buttons

  const handleResendTicket = async (itemId: any) => {
    setLoading(true);
    console.log("itemId", itemId);
    console.log('token', context.userDetails.data.token)
    await Axios.post(
      `${process.env.REACT_APP_MARKETPLACE_URL}dashboard/ticket/resend/${itemId}`,{},
      {
        headers: {
          Authorization: "Bearer " + context.userDetails.data.token
        },
      }
    )
      .then((data) => {
        let resBody = data.data.data;
        console.log(resBody, "ticket sent");
        toast.success(
          `${resBody.message} for booking ID ${resBody.data.id} / phone ${resBody.data.phone}`,
          {
            position: "top-right",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            autoClose: 5000,
          }
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response, "error sending ticket");
        setLoading(false);
        toast.error(error.response.data.detail, {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          autoClose: 5000,
        });
      });
  };

  const handleViewManifest = (e: any) => {
    // e.persist();
    console.log("handleViewManifest", e);
    let manifestData = e;
    console.log("seats", manifestData.seats);
    manifestData = manifestData.seats.join(", ");
    manifestData = {
      ...e,
      seats: manifestData,
    };
    setShowManifest(true);
    setManifestData(manifestData);
  };

  const handleViewPaymentDetails = (e: any) => {
    console.log("handleViewPaymentDetails", e);
    let paymentInfo = {
      name: e.name,
      id: e.id,
      payment_channel: e.payment_channel,
      phone: e.phone,
      departure_date: e.departure_date,
      operator: e.operator,
      total_amount: e.total_amount,
      route: e.route,
      client_confirmation_sent: e.client_confirmation_sent,
      sms: e.sms,
      transaction_message: e.payment_transaction_message,
      status: e.payment_status,
      ref_number: e.ref_number,
    };
    setPaymentData(paymentInfo);
    let paymentInfoArr = [paymentInfo]
    setCsvData(paymentInfoArr)
    // setLoading(false)
    // setLoading(true)
    // setTransactBookingId(e.id);
  };

  const handleClosePaymentDetails = () => {
    setPaymentData("")
    if (context.profileInfo.assigned_role === "trips") {
      let parsedArray = paidTickets.map(((ticket: any, idx: number) => {
        return {
          key: idx,
          booking_date: ticket.booking_date,
          name: ticket.name,
          phone: ticket.phone,
          id: ticket.id,
          trade_id: ticket.trade_id,
          source: ticket.source,
          destination: ticket.destination,
          departure_date: ticket.departure_date,
          total_amount: ticket.total_amount
        }
      }))
      console.log("paidTickets trips", parsedArray);

      setCsvData(parsedArray)
    } else {
      
    }
  }


  useEffect(() => {
    let columnInfo = [];
    if (
      context.profileInfo.assigned_role === "easycoach_user"||
      context.profileInfo.assigned_role === "trips"
    ) {
      columnInfo = paidBookingsColumnData.filter(
        (item: any, idx: number) => item.key !== "operator"
      );
      columnInfo = columnInfo.filter(
        (item: any, idx: number) => item.key !== "sms"
      );
      columnInfo = columnInfo.filter(
        (item: any, idx: number) => item.key !== "booking_channel"
      );
      columnInfo.unshift({
        title: "Booking Date",
        dataIndex: "booking_date",
        key: "booking_date",
        width: 50,
        fixed: "left",
        ellipsis: true
      });
    } else {
      columnInfo = paidBookingsColumnData;
    }
    let paidBookingColumns = [
      ...columnInfo,
      {
        title: "Action",
        key: "action",
        width: 80,
        fixed: "right",
        render: (item: any) => (
          <div
  css={theme === "default" ? actionLightBtnsCss : actionBtnsCss}
>
  {/* RESEND TICKET button with icon */}
  {(context.profileInfo.assigned_role === "easycoach_user" ||
    context.profileInfo.assigned_role === "trips") ? (
    <span></span>
  ) : (
    <span
      className="action-button"
      onClick={() => handleResendTicket(item.id)}
    >
      <i className="fas fa-redo"></i> {/* Icon for Resend Ticket */}
      RESEND TICKET
    </span>
  )}

  {/* VIEW MANIFEST button with icon */}
  {(context.profileInfo.assigned_role === "trips") ? (
    <span></span>
  ) : (
    <span
      className="action-button"
      onClick={() => handleViewManifest(item)}
    >
      <i className="fas fa-list-alt"></i> {/* Icon for View Manifest */}
      VIEW MANIFEST
    </span>
  )}

  {/* VIEW PAYMENT DETAILS button with icon */}
  <span
    className="action-button"
    onClick={() => handleViewPaymentDetails(item)}
  >
    <i className="fas fa-info-circle"></i> {/* Icon for View Payment Details */}
    VIEW PAYMENT DETAILS
  </span>
</div>

        ),
      },
    ];
    setColumns([...paidBookingColumns]);
  }, [context.profileInfo.assigned_role, theme]);

  return (
    <div
      css={paidBookingsCss}
    >
      <Filters
        theme={theme}
        setLoading={setLoading}
        loading={loading}
        nextPageNumber={nextPageNumber}
        searchQuery={searchQuery}
        setNextRolePage={setNextRolePage}
        setSearchQuery={setSearchQuery}
        setPaidTickets={setPaidTickets}
        setPaginationMetaData={setPaginationMetaData}
        setPaginationType={setPaginationType}
      />
      {showManifest ? (
        <Descriptions
          title={
            <Space
              size={"large"}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Space
                size={"large"}
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Title
                  type="secondary"
                  level={5}
                  style={{
                    color:
                      theme === "Sierra"
                        ? themeColorPalettes[theme].textColor
                        : themeColorPalettes[theme].headerTextColor,
                  }}
                >
                  MANIFEST DATA
                </Title>
                <Button
                  style={{
                    borderRadius: "10px",
                    color:
                      theme === "Sierra"
                        ? themeColorPalettes[theme].textColor
                        : themeColorPalettes[theme].iconColor,
                    backgroundColor:
                      theme === "Sierra"
                        ? themeColorPalettes[theme].extraColor
                        : themeColorPalettes[theme].sideBarColor,
                  }}
                  onClick={() => setShowManifest(false)}
                >
                  RESEND TICKET
                </Button>
              </Space>
              <Button
                shape="circle"
                onClick={() => setShowManifest(false)}
                icon={<AiOutlineClose />}
              ></Button>
            </Space>
          }
          style={{
            maxWidth: "95%",
          }}
          contentStyle={{
            backgroundColor:
              theme === "Sierra"
                ? themeColorPalettes[theme].textColor
                : themeColorPalettes[theme].iconColor,
          }}
          // size='middle'
          bordered
          column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
          <Descriptions.Item label="PNR">
            {manifestData.operator === "EasyCoach"
              ? manifestData.confirmation_response
                ? manifestData.confirmation_response[0]
                : ""
              : manifestData.operator === "Moderncoast"
              ? manifestData.confirmation_response.ticket_references
              : manifestData.confirmation_response?.confirmation_responses[0]}
          </Descriptions.Item>
          <Descriptions.Item label="Departure">
            {manifestData.source}
          </Descriptions.Item>
          <Descriptions.Item label="Destination">
            {manifestData.destination}
          </Descriptions.Item>
          <Descriptions.Item label="BKT Amount">
            {manifestData.total_amount}
          </Descriptions.Item>
          <Descriptions.Item label="Travel Date">
            {manifestData.departure_date}
          </Descriptions.Item>
          <Descriptions.Item label="Trip Type">
            <span
              style={{
                backgroundColor: themeColorPalettes[theme].backgroundColor,
                color: themeColorPalettes[theme].textColor,
                padding: "10px",
                borderRadius: "6px",
              }}
            >
              {manifestData.is_return ? "Return Trip" : "Single Trip"}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Phone No.">
            {manifestData.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Seat No.">
            {manifestData?.seats}
          </Descriptions.Item>
          <Descriptions.Item label="Passenger Name">
            {manifestData?.passengers[0].full_name}
          </Descriptions.Item>
          <Descriptions.Item label="Route Name">
            {manifestData.route}
          </Descriptions.Item>
          <Descriptions.Item label="Passenger Id">
            {manifestData?.passengers[0].id_or_passport}
          </Descriptions.Item>
          <Descriptions.Item label="Operator">
            {manifestData.operator}
          </Descriptions.Item>
          <Descriptions.Item label="Departure Time">
            {manifestData.departure_time}
          </Descriptions.Item>
        </Descriptions>
      ) : paginationMetaData && !paymentData ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative'
          }}
        >
          <Table
            columns={columns}
            dataSource={paidTickets}
            loading={loading}
            size="small"
            virtual
            scroll={{
              x: innerWidth * 0.8,
              y:
                innerHeight > 750
                  ? innerHeight * 0.7
                  : innerHeight > 500
                  ? innerHeight * 0.6
                  : innerHeight * 0.4,
            }}
            pagination={{
              onChange: (page) => {
                if (paginationType === "search") {
                  setNextPageNumber(page);
                } else {
                  setNextRolePage(page);
                }
              },
              total: paginationMetaData * 10 - 1,
            }}
          />
          <CSVLink 
            data={csvData}
            filename={"paid-bookings.csv"}
            style={{
              backgroundColor:
                theme === "Sierra"
                  ? themeColorPalettes[theme].sideBarColor
                  : themeColorPalettes[theme].extraColor,
              color:
                theme === "Sierra"
                  ? themeColorPalettes[theme].textColor
                  : themeColorPalettes[theme].iconColor,
              padding: '10px 20px',
              position: 'absolute',
              borderRadius: '5px',
              bottom: '-50px',
              right: '40px'
            }}>Export</CSVLink>
        </div>
      ) : paymentData ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative'
          }}
        >
          <Descriptions
            title={
              <Space
                size={"large"}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Space
                  size={"large"}
                  style={{
                    flex: 4,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Title
                    type="secondary"
                    level={5}
                    style={{
                      color:
                        theme === "Sierra"
                          ? themeColorPalettes[theme].textColor
                          : themeColorPalettes[theme].headerTextColor,
                    }}
                  >
                    PAYMENT DETAILS
                  </Title>
                  {(context.profileInfo.assigned_role === "trips") ? (
                    <span></span>
                  ) : (
                    <Button
                      style={{
                        borderRadius: "10px",
                        color:
                          theme === "Sierra"
                            ? themeColorPalettes[theme].textColor
                            : themeColorPalettes[theme].iconColor,
                        backgroundColor:
                          theme === "Sierra"
                            ? themeColorPalettes[theme].extraColor
                            : themeColorPalettes[theme].sideBarColor,
                      }}
                      onClick={() => handleResendTicket(transactBookingId)}
                    >
                      RESEND TICKET
                    </Button>
                  )}
                  {(context.profileInfo.assigned_role === "trips") ? (
                    <span></span>
                  ) : (
                    <Button
                      style={{
                        borderRadius: "10px",
                        color:
                          theme === "Sierra"
                            ? themeColorPalettes[theme].textColor
                            : themeColorPalettes[theme].iconColor,
                        backgroundColor:
                          theme === "Sierra"
                            ? themeColorPalettes[theme].extraColor
                            : themeColorPalettes[theme].sideBarColor,
                      }}
                      onClick={() => showQrModal()}
                    >
                      INITIATE REFUND
                    </Button>
                  )}
                  <Modal
                    open={qrOpen}
                    title="Verify Account"
                    onOk={handleQrOk}
                    onCancel={handleQrCancel}
                    footer={[
                      <Button key="submit" type="primary" loading={qrLoading} onClick={handleQrCancel}>
                        Close
                      </Button>
                    ]}
                  >
                    {
                      qrCodeState.status === 'fetching'
                        ? <p>Retrieving Qr code</p> 
                        : qrCodeState.status === 'fetched'
                        ? <p><img src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCodeState.data.qr_code)}`} alt="My SVG" /></p> 
                        : <p>Idling</p>
                    }
                  </Modal>
                </Space>
                <Button
                  shape="circle"
                  onClick={() => handleClosePaymentDetails()}
                  icon={<AiOutlineClose />}
                ></Button>
              </Space>
            }
            style={{
              maxWidth: "95%",
            }}
            contentStyle={{
              backgroundColor:
                theme === "Sierra"
                  ? themeColorPalettes[theme].textColor
                  : themeColorPalettes[theme].iconColor,
         
            }}
            labelStyle={{
              color: theme === "Sierra"
              ? themeColorPalettes[theme].textColor
              : themeColorPalettes[theme].iconColor,
            }}
            // size='middle'
            bordered
            column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="Traveler">
              {paymentData.name}
            </Descriptions.Item>
            <Descriptions.Item label="Booking ID">
              {paymentData.id}
            </Descriptions.Item>
            <Descriptions.Item label="Payment Channel">
              {paymentData.payment_channel}
            </Descriptions.Item>
            <Descriptions.Item label="Phone No.">
              {paymentData.phone}
            </Descriptions.Item>
            <Descriptions.Item label="Travel Date">
              {paymentData.departure_date}
            </Descriptions.Item>
            <Descriptions.Item label="Operator">
              {paymentData.operator}
            </Descriptions.Item>
            <Descriptions.Item label="Amount">
              {paymentData.total_amount}
            </Descriptions.Item>
            <Descriptions.Item label="Route">
              {paymentData.route}
            </Descriptions.Item>
            <Descriptions.Item label="Ticket Sent">
              {paymentData.client_confirmation_sent ? "Yes" : "No"}
            </Descriptions.Item>

            <Descriptions.Item label="Transaction Message">
              {paymentData.transaction_message}
            </Descriptions.Item>
            <Descriptions.Item label="Payment Status">
              {paymentData.status}
            </Descriptions.Item>
            <Descriptions.Item label="Ref No.">
              {paymentData.ref_number}
            </Descriptions.Item>
            <Descriptions.Item label="SMS">{paymentData.sms}</Descriptions.Item>
          </Descriptions>
          <CSVLink data={csvData} style={{
            backgroundColor:
              theme === "Sierra"
                ? themeColorPalettes[theme].sideBarColor
                : themeColorPalettes[theme].extraColor,
            color:
              theme === "Sierra"
                ? themeColorPalettes[theme].textColor
                : themeColorPalettes[theme].iconColor,
            padding: '10px 20px',
            position: 'absolute',
            borderRadius: '5px',
            bottom: '-50px',
            right: '40px'
          }}>Export</CSVLink>
        </div>
      ) : (
        <div style={{ backgroundColor: "pink" }}>"loading..."</div>
      )}
      <ToastContainer 
        position="top-right"
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default PaidBookings;
